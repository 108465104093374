import CheckCircle from '../components/icons/CheckCircle'
import Link from 'next/link'
import Layout from '../components/layouts/AppLayout'
import Alert from '../components/Alert'
import { useRouter } from 'next/router'
import { useMemo, useState } from 'react'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'
import Head from 'next/head'
import useBackendValidationErrors from '../services/backendvalidationerrors'
import useLogin from '../hooks/useLogin'

export default function Login () {
  const { login } = useLogin()
  const { t } = useTranslation(['alerts', 'login'])
  const [email, setEmail] = useState('')
  const [error, setError] = useState(null)
  const [password, setPassword] = useState('')
  const router = useRouter()
  const { getValidationErrorsAsAlert } = useBackendValidationErrors(error)
  const variant = useMemo(() => (
    router.query.signup === 'success' || router.query.signup === 'unverified' ? 'success' : 'danger'), [router.query.signup]
  )

  const makeLogin = async (e) => {
    e.preventDefault()

    try {
      const { currentUser } = await login(email, password)

      if (router.query.redirect) {
        router.push(decodeURI(router.query.redirect))
        return
      }

      switch (currentUser.role) {
        case 'Admin':
          router.push('/admin/dashboard')
          break
        case 'Seller':
          router.push('/seller/dashboard')
          break
        case 'Distributor':
          router.push('/distributor/dashboard')
          break
        case 'Buyer':
          router.push('/plugins')
          break
        default:
          router.push('/')
      }
    } catch (e) {
      setError(e)
    }
  }

  return (
    <Layout variant="light-blue">
      <Head>
        <title>{t('meta.title', { ns: 'login' })}</title>
      </Head>
      <div className="bg-light-blue py-lg-5">
        <div className="container py-lg-5 py-3">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-4 col-12">
              {router.query.signup && (
              <Alert variant={variant}>
                {t(`signup.verification.${router.query.signup}`)}
              </Alert>
              )}
              {error && (getValidationErrorsAsAlert)}
              <form onSubmit={e => makeLogin(e)}
                className={'bg-white shadow-sm rounded appendix px-4 py-5 login__appendix'}
              >
                <h2 className="fs-3 mb-3 fw-bold text-dark">{t('form.title', { ns: 'login' })}</h2>
                <div className="form-group mt-3">
                  <label className="form-label"
                    htmlFor="email"
                  >{t('form.email', { ns: 'login' })}</label>
                  <input required
                    type="email"
                    className="form-control"
                    id="email"
                    onChange={e => setEmail(e.target.value)}
                  />
                </div>
                <div className="form-group mt-3">
                  <label className="form-label"
                    htmlFor="password"
                  >{t('form.password', { ns: 'login' })}</label>
                  <input
                    required
                    type="password"
                    className="form-control"
                    id="password"
                    onChange={e => setPassword(e.target.value)}
                  />
                </div>
                <div className="mt-3">
                  <Link href="/recovery">
                    <a className="fs-5 text-decoration-none">{t('form.forgot_password', { ns: 'login' })}</a>
                  </Link>
                </div>
                <button type="submit"
                  className="btn btn-primary mt-4"
                >{t('form.login', { ns: 'login' })}</button>
              </form>
            </div>
            <div className="col-lg-7 col-12 mt-5 mt-lg-0">
              <h1 className="fs-3 fw-semibold text-dark">
                {t('signup.title', { ns: 'login' })}
              </h1>
              <p>
                {t('signup.description', { ns: 'login' })}
              </p>
              <ul className="list-unstyled">
                {
                  t('signup.list', { ns: 'login', returnObjects: true }).map((item, key) => (
                    <li key={key}
                      className="p-2 d-flex align-items-start"
                    >
                      <CheckCircle variant="primary" />
                      <span className="ms-3" >{item}</span>
                    </li>
                  ))
                }
              </ul>
              <div className="d-flex align-items-center flex-wrap">
                <Link href="/sign-up">
                  <a className="btn btn-outline-primary mb-3 me-sm-3">{t('signup.register_as_customer', { ns: 'login' })}</a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export async function getServerSideProps ({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['alerts', 'login', 'header', 'footer', 'languages', 'applications', 'routes']))
    }
  }
}
