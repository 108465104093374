export default function CheckCircle ({ width = 11, height = 11, variant }) {
  return (
    <div className={`flex-shrink-0 d-flex align-items-center justify-content-center rounded-circle bg-${variant}`}
      style={{ width: 22 + 'px', height: 22 + 'px' }}
    >
      <svg viewBox="0 0 24 24"
        height={height}
        width={width}
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="4"
      >
        <path d="M23.25.749,8.158,22.308a2.2,2.2,0,0,1-3.569.059L.75,17.249"
          fill="none"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
        </path>
      </svg>
    </div>
  )
}
