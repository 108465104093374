import { setCredentials } from '../features/auth/authReducer'
import { client, withToken } from '../services/client'
import { CURRENT_USER_WITH_COMPANY } from '../services/graphql/queries/user'
import { LOGIN_USER } from '../services/graphql/mutations/login'
import store from '../app/store'
import Cookies from 'js-cookie'

export default function useLogin () {
  /**
   * Login user and return the response data from the api
   */
  const login = async (email, password) => {
    const { data } = await client('en').mutate({
      mutation: LOGIN_USER,
      variables: {
        input: {
          email,
          password
        }
      }
    })

    if (data.graphQLErrors) {
      throw data
    }

    const token = data.login.token.split('|')[1]

    // Store token so we know if the user is logged in
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('token', token)
    }

    // Set cookie with token for SSR
    Cookies.set('token', token, { expires: 60 })

    // Update store with user data
    const { data: { currentUser } } = await withToken(token)
      .query({ query: CURRENT_USER_WITH_COMPANY, fetchPolicy: 'no-cache' })

    // Store login data in state
    store.dispatch(setCredentials({
      me: currentUser,
      token
    }))

    return {
      response: data,
      currentUser
    }
  }

  return {
    login
  }
}
